import React from "react";
import "./Clear.css";

class Clear extends React.Component {
  render() {
    return (
      <div className="clear" />
    )
  }
}

export default Clear;