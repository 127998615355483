import React from "react";
import "./NavBarSpacer.css";

class NavBarSpacer extends React.Component {
  render() {
    return (
      <div className="nav-bar-spacer" />
    )
  }
}

export default NavBarSpacer;