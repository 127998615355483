import React from "react";
import './AppPage.css';

import NavBar from "../Organisms/NavBar"
import NavBarSpacer from "../Atoms/NavBarSpacer"
import Wrapper from "../Atoms/Wrapper"

class AppPage extends React.Component {
  componentDidMount() {
    // New Page Mounts, scroll to top 
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={this.props.matcher + " app-page"}>
        <NavBar />

        <NavBarSpacer />
        <Wrapper>
          {/* Pass Through Page Content */}
          {this.props.children}
        </Wrapper>
      </div>
    );
  }
}

export default AppPage;
