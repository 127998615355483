import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Firebase 
import base, { firebaseApp } from "./lib/base";

// Pages
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/Profile";
import Settings from "./Pages/Settings";
import AuthLogin from "./Pages/AuthLogin";
import AuthCreate from "./Pages/AuthCreate";
import AuthRecover from "./Pages/AuthRecover";

// Pieces
import Loader from "./Components/Atoms/Loader";

// Router itself
class AppRouter extends React.Component {
  state = {
    authed: false,
    loading: true
  };

  componentDidMount() {
    // When the root loads, add a listener to Firebase Auth
    firebaseApp.auth().onAuthStateChanged(user => {
      // if we don't have a user, then simply finish by updating state
      if (!user) {
        this.setState({ authed: false, loading: false });
      } else {
        // however, if there is a user...
        // 1. extract user data from user object
        const userData = { email: user.email, id: user.uid };

        // 2. ensure the user's data is up-to-date with the user collection
        base.updateDoc("users/" + user.uid, userData).catch(error => {
          console.log(error);
          if (error.code === "not-found") {
            base.addToCollection("users", userData, user.uid).catch(error => {
              console.log(error);
            });
          }
        });

        // 3. update state accordingly > no longer loading, we are authenticated
        this.setState({ authed: true, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <Loader />
    ) : (
        <div>
          <BrowserRouter>
            <Switch>
              {/* Main Page - Dashboard */}
              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/"
                component={Dashboard} />

              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/profile"
                component={Profile} />

              <PrivateRoute
                authed={this.state.authed}
                exact
                path="/settings"
                component={Settings} />

              {/* Account Routes */}
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/account/create"
                component={AuthCreate} />
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/account/login"
                component={AuthLogin} />
              <PublicRoute
                authed={this.state.authed}
                exact
                path="/account/recover"
                component={AuthRecover} />

              {/* 404/not-found fallback - redirects to "/"  being a PrivateRoute*/}
              <FallbackRoute />
            </Switch>
          </BrowserRouter>
        </div>
      );
  }
}

// Private Route - Requires Authentication, Redirects User if Not Authenticated
function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/account/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

// Public Route - Only Available to Not Authenticated Users
function PublicRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === false ? (
          <Component {...props} />
        ) : (
            <Redirect to="/" />
          )
      }
    />
  );
}

// 404 Route - Redirects to Root
function FallbackRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        <Redirect to="/" />
      }
    />
  );
}



export default AppRouter;
