import React from "react";
import "./Form.css";

class Form extends React.Component {
  render() {
    return (
      <form
        acceptCharset={this.props.acceptCharset}
        action={this.props.action}
        autoComplete={this.props.autoCompleteInputs}
        encType={this.props.encType}
        method={this.props.method}
        name={this.props.name}
        noValidate={this.props.noValidate}
        target={this.props.target}
        onSubmit={this.props.onSubmit}
      >
        {this.props.children}
      </form>
    )
  }
}

export default Form;