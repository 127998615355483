import React from "react";
import { signOut } from "../lib/auth";

import AppPage from "../Components/Templates/AppPage";

import Button from "../Components/Atoms/Button";
import Heading from "../Components/Atoms/Heading";
import Title from "../Components/Atoms/Title";

class Profile extends React.Component {
  logout = () => {
    signOut();
  };

  render() {
    return (
      <AppPage matcher="profile">
        <Heading>Profile Page</Heading>

        <Title>Profile Page</Title>
      </AppPage>
    );
  }
}

export default Profile;
