import firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "./base";

export function createAccount(email, password) {
  return firebaseApp.auth().createUserWithEmailAndPassword(email, password);
}

export function signOut() {
  return firebaseApp.auth().signOut();
}

export function signIn(email, password) {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password);
}

export function forgotPassword(email) {
  return firebaseApp.auth().sendPasswordResetEmail(email);
}

export function authenticateWithFacebook() {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  return firebaseApp.auth().signInWithPopup(facebookProvider);
}

export function authenticateWithGoogle() {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  return firebaseApp.auth().signInWithPopup(googleProvider);
}
