import React from "react";
import { Link } from "react-router-dom";

import AuthPage from "../Components/Templates/AuthPage";
import Heading from "../Components/Atoms/Heading";
import Button from "../Components/Atoms/Button";
import Form from "../Components/Atoms/Form";
import Divider from "../Components/Atoms/Divider";

import { forgotPassword } from "../lib/auth";

class AuthRecover extends React.Component {
  state = {
    loading: false,
    errorMessage: null,
    showSuccess: false,
    successMessage: "Please check your email, we just sent instructions on how to set your password.",
    promptMessage: "Enter your email address and we'll send you instructions to update your password."
  };

  performForgot = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.emailRef.value;
    forgotPassword(email)
      .then(() => {
        this.setState({
          showSuccess: true,
          errorMessage: null,
          loading: false
        });
      })
      .catch(error => {
        // console.log(error);
        var simpleMessage = "";
        switch (error.code) {
          case "auth/invalid-email": {
            simpleMessage = "Please enter a valid email address.";
            break;
          }
          case "auth/user-not-found": {
            simpleMessage =
              "The email address entered does not have an account.";
            break;
          }
          default: {
            simpleMessage = "There was a problem. Please try again.";
          }
        }
        this.setState({ errorMessage: simpleMessage, loading: false });
      });
  };

  render() {
    return (
      <AuthPage>
        <Heading>Recover your account</Heading>

        {this.state.showSuccess ? (<div>{this.state.successMessage}</div>) : (
          <div>
            {this.state.promptMessage}

            <Divider />

            {this.state.errorMessage ? (<div>{this.state.errorMessage}</div>) : ("")}


            <Form
              action=""
              className="signup"
              onSubmit={this.performForgot}
            >
              <input
                className="login"
                ref={emailRef => (this.emailRef = emailRef)}
                name="email"
                placeholder="Email address"
                type="email"
              />

              <Button>
                Continue
              </Button>

            </Form>

          </div>
        )}




        <Link to="/account/login">
          Back to Login
        </Link>

      </AuthPage>
    );
  }
}

export default AuthRecover;
