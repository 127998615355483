import React from "react";
import {
  createAccount,
  authenticateWithFacebook,
  authenticateWithGoogle
} from "../lib/auth"

import AuthPage from "../Components/Templates/AuthPage";
import Heading from "../Components/Atoms/Heading";
import Button from "../Components/Atoms/Button";
import Form from "../Components/Atoms/Form";
import Divider from "../Components/Atoms/Divider";
import Loader from "../Components/Atoms/Loader";
import { Link } from "react-router-dom";

class AuthCreate extends React.Component {
  state = {
    errorMessage: null,
    loading: false
  };

  performSignup = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const email = this.emailRef.value;
    const password = this.passwordRef.value;
    createAccount(email, password).catch(error => {
      var simpleMessage = "";
      switch (error.code) {
        case "auth/invalid-email": {
          simpleMessage = "Please enter a valid email address.";
          break;
        }
        case "auth/operation-not-allowed": {
          simpleMessage = "Sign up is disabled.";
          break;
        }
        case "auth/weak-password": {
          simpleMessage = "Please enter a more secure password.";
          break;
        }
        case "auth/email-already-in-use": {
          simpleMessage =
            "There is already an account with this email address.";
          break;
        }
        default: {
          simpleMessage = "There was a problem. Please try again.";
        }
      }
      this.setState({ errorMessage: simpleMessage, loading: false });
    });
  };

  performFacebookAuth = () => {
    authenticateWithFacebook();
  };

  performGoogleAuth = () => {
    authenticateWithGoogle();
  };

  render() {
    return (
      <AuthPage>
        <Heading>Create an account</Heading>

        {this.state.loading ? (<Loader />) : (<div>

          {this.state.errorMessage ? (<div>{this.state.errorMessage}</div>) : ("")}

          <Form action="" className="signup" onSubmit={this.performSignup}>
            <input
              className="login"
              ref={emailRef => (this.emailRef = emailRef)}
              name="email"
              placeholder="Email"
              type="email"
            />
            <input
              className="login"
              ref={passwordRef => (this.passwordRef = passwordRef)}
              name="password"
              placeholder="Choose a password"
              type="password"
            />
            <Button full={true}>
              Continue
            </Button>
          </Form>

          <Divider />

          <Button
            onClick={this.performFacebookAuth}
            full={true}
            backgroundColor="#4267b2">
            Sign up with Facebook
          </Button>

          <Button
            onClick={this.performGoogleAuth}
            full={true}
            backgroundColor="#f1396a">
            Sign up with Google
          </Button>


          <Link to="/account/login">
            Already have an account?
          </Link>
        </div>)}
      </AuthPage>
    );
  }
}

export default AuthCreate;
