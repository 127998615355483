import Rebase from "re-base";
import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBdwTHLrZ7bcc4Ilrn1OGusrsGa8DbYUdM",
  authDomain: "simple-trips.firebaseapp.com",
  databaseURL: "https://simple-trips.firebaseio.com",
  projectId: "simple-trips",
  storageBucket: "simple-trips.appspot.com",
  messagingSenderId: "571112164478"
};

// initialize firebase app using config
const firebaseApp = Firebase.initializeApp(config);
// initialize firestore data store
const db = firebaseApp.firestore(firebaseApp);
// wrap the firestore in re-base for ease of use
const base = Rebase.createClass(db);

export { firebaseApp };
export default base;
