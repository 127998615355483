import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";

import NavBarLinks from "../Molecules/NavBarLinks";
import Wrapper from "../Atoms/Wrapper";
import Clear from "../Atoms/Clear";

import { ReactComponent as Logo } from '../../svg/logo-blue.svg'

class NavBar extends React.Component {
  render() {
    return (
      <div className="nav-bar">
        <Wrapper>
          <div className="branding">
            <Link to="/dashboard">
              <Logo width="24px" height="24px" />
            </Link>
          </div>

          <NavBarLinks />
          <Clear />
        </Wrapper>
      </div>
    )
  }
}

export default NavBar;