import React from "react";
import './AuthPage.css';

import Wrapper from "../Atoms/Wrapper"
import { ReactComponent as Logo } from '../../svg/logo-blue.svg'


class AuthPage extends React.Component {
  componentDidMount() {
    // New Page Mounts, scroll to top 
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="auth-page">
        <Wrapper>
          <div className="auth-page-box">
            <Logo width="24px" height="24px" />

            {/* Pass Through Page Content */}
            {this.props.children}
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default AuthPage;
