import React from "react";
import "./Title.css";

class Title extends React.Component {
  render() {
    return (
      <div className="title-container">
        {this.props.children}
      </div>
    )
  }
}

export default Title;