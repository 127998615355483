import React from "react";
import { Link } from "react-router-dom";

import AuthPage from "../Components/Templates/AuthPage";
import Heading from "../Components/Atoms/Heading";
import Button from "../Components/Atoms/Button";
import Form from "../Components/Atoms/Form";
import Divider from "../Components/Atoms/Divider";
import Loader from "../Components/Atoms/Loader";

import {
  signIn,
  authenticateWithFacebook,
  authenticateWithGoogle
} from "../lib/auth";

class AuthLogin extends React.Component {
  state = {
    errorMessage: null,
    loading: false
  };

  performLogin = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const email = this.emailRef.value;
    const password = this.passwordRef.value;
    signIn(email, password).catch(error => {
      var simpleMessage = "";
      switch (error.code) {
        case "auth/invalid-email": {
          simpleMessage = "Please enter a valid email address.";
          break;
        }
        case "auth/user-disabled": {
          simpleMessage = "Your account has been disabled.";
          break;
        }
        case "auth/user-not-found": {
          simpleMessage = "The email address entered does not have an account.";
          break;
        }
        case "auth/wrong-password": {
          simpleMessage = "Incorrect password.";
          break;
        }
        default: {
          simpleMessage = "There was a problem. Please try again.";
        }
      }
      this.setState({ errorMessage: simpleMessage, loading: false });
    });
  };

  performFacebookAuth = () => {
    authenticateWithFacebook();
  };

  performGoogleAuth = () => {
    authenticateWithGoogle();
  };

  render() {
    return (
      <AuthPage>
        <Heading>Log in</Heading>

        {this.state.loading ? (<Loader />) : (<div>

          {this.state.errorMessage ? (<div>{this.state.errorMessage}</div>) : ("")}


          <Form className="login" onSubmit={() => { }}>
            <input
              className="login"
              ref={emailRef => (this.emailRef = emailRef)}
              name="email"
              placeholder="Email"
              type="email"
            />
            <input
              className="login"
              ref={passwordRef => (this.passwordRef = passwordRef)}
              name="password"
              placeholder="Password"
              type="password"
            />
            <Button full={true}>
              Continue
            </Button>
            <Link to="/account/recover">
              Need password help?
          </Link>
          </Form>

          <Divider />

          <Button
            onClick={this.performFacebookAuth}
            full={true}
            backgroundColor="#4267b2">
            Login with Facebook
          </Button>

          <Button
            onClick={this.performGoogleAuth}
            full={true}
            backgroundColor="#f1396a">
            Login with Google
         </Button>


          <Link to="/account/create">
            Don't have an account?
          </Link>
        </div>)}

      </AuthPage>
    );
  }
}

export default AuthLogin;
