import React from "react";
import "./Heading.css";

class Heading extends React.Component {
  render() {
    return (
      <div className="heading-container">
        {this.props.children}
      </div>
    )
  }
}

export default Heading;