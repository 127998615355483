import React from "react";
import "./NavBarLinks.css";

import NavBarLinksItem from "../Atoms/NavBarLinksItem";

class NavBarLinks extends React.Component {
  render() {
    return (
      <div className="nav-bar-links">
        <NavBarLinksItem destination="/" matcher="dashboard" title="Home" />
        <NavBarLinksItem destination="/profile" matcher="profile" title="Profile" />
        <NavBarLinksItem destination="/settings" matcher="settings" title="Settings" />
      </div>
    )
  }
}

export default NavBarLinks;