import React, { Component } from "react";
import { ThreeBounce } from "better-react-spinkit";
import "./Loader.css";

class Loader extends Component {
  render() {
    return (
      <div className="loader-container">
        <ThreeBounce size={15} color="#396af1" />
      </div>
    );
  }
}

export default Loader;
