import React from "react";
import './RequestListItem.css';

import TimeAgo from "react-timeago";

class RequestListItem extends React.Component {
  render() {
    return (
      <div key={this.props.index} className="request-list-item">
        <p>{this.props.request.description}</p>
        <p><TimeAgo date={this.props.request.createdAt} minPeriod={10} /></p>
      </div>
    );
  }
}

export default RequestListItem;
