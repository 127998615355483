import React from "react";
import './RequestListNew.css';

import base, { firebaseApp } from "../../lib/base";

import Button from "../Atoms/Button";
import Form from "../Atoms/Form";

class RequestListNew extends React.Component {

  createRequest = event => {
    // Prevent the form from submitting
    event.preventDefault();

    // Prepare the request data from the new request form
    const requestDescription = this.requestDescriptionRef.value;
    if (requestDescription.length === 0 || requestDescription === "") {
      return; // don't allow empty requests
    }

    // attach metadata (uid, timestamp, etc)
    const uid = firebaseApp.auth().currentUser.uid;
    const timestamp = new Date().getTime();
    const requestData = {
      description: requestDescription,
      createdByUserId: uid,
      lastActivity: timestamp,
      createdAt: timestamp
    };
    // save to firestore
    base.addToCollection("users/" + uid + "/requests", requestData);

    // Reset the request form
    event.currentTarget.reset();
  };

  render() {
    return (
      <div className="request-list-new">
        <p>Make a new request.</p>

        <Form
          action=""
          className="request-list-new-form"
          onSubmit={this.createRequest}>

          <textarea
            className="requestdescription"
            ref={requestDescriptionRef =>
              (this.requestDescriptionRef = requestDescriptionRef)
            }
            name="requestDescription"
            placeholder="Start typing..."
            type="text"
          />

          <Button>
            Submit request
          </Button>
        </Form>
      </div >
    );
  }
}

export default RequestListNew;
