import React from "react";
import './RequestList.css';

import base, { firebaseApp } from "../../lib/base";

import Title from "../Atoms/Title";
import Loader from "../Atoms/Loader";
import RequestListItem from "../Molecules/RequestListItem";
import RequestListNew from "../Molecules/RequestListNew";

class RequestList extends React.Component {
  state = {
    loading: false,
    requests: []
  };

  componentWillMount() {
    this.bindRequests();
  }

  bindRequests = () => {
    this.setState({ loading: true });
    const uid = firebaseApp.auth().currentUser.uid;
    base.bindCollection("users/" + uid + "/requests", {
      context: this,
      state: "requests",
      withRefs: true,
      withIds: true,
      query: ref => {
        return ref
          .where("lastActivity", ">", 0)
          .orderBy("lastActivity", "desc");
      },
      then: data => {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div className="request-list-container">
        <Title>Concierge Requests</Title>

        <RequestListNew />

        {this.state.loading ? (
          <Loader />
        ) : (
            <div className="request-list">
              {this.state.requests.length === 0 ? (
                <div>No Requests Yet (empty)</div>
              ) : (
                  <div className="request-list-inner-container">
                    {this.state.requests.map((request, index) => {
                      return (
                        <RequestListItem
                          key={index}
                          index={index}
                          request={request} />
                      )
                    })}
                  </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default RequestList;
