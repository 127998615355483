import React from "react";
import { Link } from "react-router-dom";
import "./NavBarLinksItem.css";

class NavBarLinksItem extends React.Component {
  render() {
    return (
      <div className={this.props.matcher + " nav-bar-links-item"}>
        <Link to={this.props.destination}>
          {this.props.title}
        </Link>
      </div>
    )
  }
}

export default NavBarLinksItem;