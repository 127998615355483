import React from "react";
import { signOut } from "../lib/auth";

import AppPage from "../Components/Templates/AppPage";

import Button from "../Components/Atoms/Button";
import Heading from "../Components/Atoms/Heading";
import Title from "../Components/Atoms/Title";
import RequestList from "../Components/Organisms/RequestList";

class Dashboard extends React.Component {
  logout = () => {
    signOut();
  };

  render() {
    return (
      <AppPage matcher="dashboard">

        <RequestList />

      </AppPage>
    );
  }
}

export default Dashboard;
