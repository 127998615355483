import React from "react";
import "./Button.css";

const styles = {
  textAlign: 'left'
};

class Button extends React.Component {
  styles = () => {
    const styles = {};
    if (this.props.backgroundColor) {
      styles.backgroundColor = this.props.backgroundColor;
    }

    return styles;
  }
  render() {
    return (
      <div className="button-container">
        <button
          className={
            "button "
            + (this.props.full ? "full " : "")
            + (this.props.minor ? "minor " : "")
          }
          onClick={this.props.onClick}
          style={this.styles()}>
          {this.props.children}
        </button>
      </div >
    )
  }
}

export default Button;